import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import Paragraph from "../components/story/paragraph"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Features from "../components/features/features"
import Podcasts from "../components/podcast/podcasts"
import BookBox from "../components/event/book-box"
import Wrapper from "../components/layout/wrapper"
import AboutBox from "../components/event/about-box"
import MarkdownContent from "../components/team/mdxhtmlConverter"
import Speakers from "../components/event/speakers"

// Static files
import Logo from "../images/logo-show.svg"
import Line from "../images/line-sports.svg"
import cultivating from "../images/event/cultivating-knowledge.svg"
import DescBg from "../images/learn-bg-desc.svg"
import DateIcon from "../images/event/date.svg"
import TimeIcon from "../images/event/time.png"
import LocationIcon from "../images/event/location.svg"
import SpeakerImage1 from "../images/event/majid.svg"
import SpeakerImage2 from "../images/event/meherun.svg"
import SpeakerImage3 from "../images/event/more-speakers.svg"
import EventLastBg from "../images/event/event-last-bg.svg"
const LineData = (
  [Line, 'top-0', 'right-36', 'scale-y-104', '']
)



export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "event"}}}) {
    nodes {
      frontmatter {
        metaDescription
        metaTitle
        ctaHeading
        ctaHeading2
        ctaHeading3
        templateKey
        intro {
          heading
          subheading                              
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        thirdSection {
          date{
            heading
            text
          }
          time{
            heading
            text
          }
          location{
            heading
            text
          }
          button{
            text
          }
        }
        fifthSection{
          heading
          button1{
            text
          }
          button2{
            text
          }
          button3{
            text
          }
          button4{
            text
          }
          button5{
            text
          }
        }
        fourthSection {
          secDescription
        }
        sixthSection {
          heading
          text
        }
        seventhSection {
          heading
        }
        eighthSection {
          heading
          subheading
          button{
            text
          }
        }
        podcasts {
          heading
          subheading
          podcasturls {
            youtube
            spotify
            google
            apple
          }
        }
        features {
          showcase {
            heading
            text
            image {
              publicURL
            }
          }
          share {
            heading
            text
            image {
              publicURL
            }
          }
          learn {
            heading
            text
            image {
              publicURL
            }
          }
        }
      }
    }
  }
}
` 
const IndexPage = ({ data }) => {
  const allData = data?.allMdx?.nodes[0]
  const allFeatures = Object.values(allData?.frontmatter?.features);
  const allIntro = allData?.frontmatter?.intro 
  const allThird = allData?.frontmatter?.thirdSection
  const allFourth = allData?.frontmatter?.fourthSection
  const allFifth = allData?.frontmatter?.fifthSection
  const allSixth = allData?.frontmatter?.sixthSection  
  const allSeventh = allData?.frontmatter?.seventhSection  
  const allEighth = allData?.frontmatter?.eighthSection
  const allPodcasts = allData?.frontmatter?.podcasts
  const Stories = () => (
    <div className={`flex flex-col items-center xl:items-start justify-between gap-y-20 xl:flex-row`}>
          <a href="https://form.jotform.com/243122156454349" target="_blank"><img 
              src={cultivating} 
              className="w-[100%] h-[auto] mb-16 xl:mb-6 mx-auto" 
              alt={allIntro[0].heading} 
          /></a>
          
      </div>
     
      
    
  )
  return (
    <Layout line={LineData} fullWidth>
      <Seo 
        title={allData.frontmatter.metaTitle}
        description={allData?.frontmatter?.metaDescription}
      />
        <Wrapper>
          <div className="flex flex-col gap-y-32 my-16">
            <Stories/>
          </div>
        </Wrapper>
        
      <AboutBox tagline={allIntro[0].heading+": "+allIntro[0].subheading} position="left"/>
      <Wrapper>
      
      <div className="flex flex-col gap-y-10 my-16 lg:my-48 relative" style={{ marginBottom: '60px'}}>
            <div className="
                flex flex-col
                w-full
                p-6 1xs:p-10 sm:p-16
                lg:px-36 
                lg:py-16
                bg-white
                rounded-lg
                "
            >
              <div className="flex tbc-sec flex-col md:flex-row" style={{ marginBottom: '20px'}}>
                <div className="flex tbc-img flex-initial flex-col items-center gap-y-10" style={{justifyContent: 'center', marginRight: '30px'}}><img src={DateIcon} style={{ maxWidth: '60px'}}/></div>
                <div className="flex tbc-cont flex-initial flex-col items-center gap-y-10" style={{justifyContent: 'center'}}><h2 className="sm:text-xl lg:text-3xl" style={{color: '#050239', fontWeight: '500'}}>{allThird.date.heading}: {allThird.date.text}</h2></div>
              </div>
              <div className="flex tbc-sec flex-col md:flex-row" style={{ marginBottom: '20px'}}>
                <div className="flex tbc-img flex-initial flex-col items-center gap-y-4" style={{justifyContent: 'center', marginRight: '30px'}}><img src={TimeIcon} style={{ maxWidth: '60px'}}/></div>
                <div className="flex tbc-cont flex-initial flex-col items-center gap-y-4" style={{justifyContent: 'center'}}><h2 className="sm:text-xl lg:text-3xl" style={{color: '#050239', fontWeight: '500'}}>{allThird.time.heading}: {allThird.time.text}</h2></div>
              </div>
              <div className="flex tbc-sec flex-col md:flex-row">
                <div className="flex tbc-img flex-initial flex-col items-center gap-y-4" style={{justifyContent: 'center', marginRight: '30px'}}><img src={LocationIcon} style={{ maxWidth: '60px'}}/></div>
                <div className="flex tbc-cont flex-initial flex-col items-center gap-y-4" style={{justifyContent: 'center'}}><h2 className="sm:text-xl lg:text-3xl" style={{color: '#050239', fontWeight: '500'}}>{allThird.location.heading}: {allThird.location.text}</h2></div>
              </div>
              </div>
              </div>
              <div className="flex flex-col justify-center items-center"><a className="book-now-btn" href="https://form.jotform.com/243122156454349" target="_blank" style={{backgroundColor: '#4054D6', color: '#F1F3F8', display: 'inline-block', padding: '10px 40px', borderRadius: '50px', fontSize:'22px', textTransform: 'uppercase'}}>{allThird.button.text}</a></div>
          <div className="flex flex-col gap-y-10 my-16 lg:my-48 relative">
            <div className="
                flex flex-col
                w-full
                p-6 1xs:p-10 sm:p-16
                lg:px-36 
                lg:py-32
                bg-white
                rounded-lg
                "
            >
              <img 
                  src={DescBg}
                  alt=""
                  className="
                  w-[1064.09px] h-[800px]
                  -top-28
                  left-32
                  -z-50
                  absolute hidden xl:block"
              />
              <MarkdownContent 
                content={allFourth.secDescription} 
                className="prose prose-base sm:prose-2xl max-w-none text-dark" 
              />
            </div>
          </div>
        
      </Wrapper>
      <div class=" w-full  bg-blue-darker  py-28 " style={{backgroundColor: '#050239'}}>
        <Wrapper> 
        <h2 class="text-2xl sm:text-5xl text-center text-white" style={{ fontSize:'30px', marginBottom: '40px', fontWeight: '500'}}>{allFifth.heading}</h2>
        <div className="flex flex-col justify-center items-center home-expect" style={{maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto'}}>
          <a  href="#" style={{width: '100%', backgroundColor: '#4054D6', color: '#F1F3F8', display: 'block', padding: '20px 40px', borderRadius: '50px', fontSize:'30px', fontWeight: '500', textAlign: 'center'}}>{allFifth.button1.text}</a>
          <a  href="#" style={{width: '100%', backgroundColor: '#F1F3F8', color: '#050239', display: 'block', padding: '20px 40px', borderRadius: '50px', fontSize:'30px', fontWeight: '500', textAlign: 'center'}}>{allFifth.button2.text}</a>
          <a  href="#" style={{width: '100%', backgroundColor: '#FC565A', color: '#050239', display: 'block', padding: '20px 40px', borderRadius: '50px', fontSize:'30px', fontWeight: '500', textAlign: 'center'}}>{allFifth.button3.text}</a>
          <a  href="#" style={{width: '100%', backgroundColor: '#FECB2E', color: '#050239', display: 'block', padding: '20px 40px', borderRadius: '50px', fontSize:'30px', fontWeight: '500', textAlign: 'center'}}>{allFifth.button4.text}</a>
          <a  href="#" style={{width: '100%', backgroundColor: '#F1F3F8', color: '#050239', display: 'block', padding: '20px 40px', borderRadius: '50px', fontSize:'30px', fontWeight: '500', textAlign: 'center'}}>{allFifth.button5.text}</a>
        </div>
          
        </Wrapper>
      </div>
        
        <Wrapper> 
        <div className="flex flex-col gap-y-10 my-16 lg:my-48 relative" style={{ marginBottom: '60px'}}>
            <div className="
                flex flex-col
                w-full
                p-6 1xs:p-10 sm:p-16
                lg:px-16 
                lg:py-16
                bg-white
                rounded-lg
                "
                style={{maxWidth: '550px'}}
            >
              <div className="" style={{ marginBottom: '20px'}}>
              <h2 className="sm:text-xl lg:text-3xl" style={{color: '#050239', fontWeight: '500'}}>{allSixth.heading}:</h2>
              <p>{allSixth.text}</p>
              </div>
              </div>
              </div>
          <Speakers 
            features={allFeatures}
            imgClass="w-[300px]"
          />
          <AboutBox tagline={allSeventh.heading} position="center"/>

          <div className="flex flex-col gap-y-10 my-16 lg:my-48 relative">
            <div className="
                flex flex-col
                w-full
                p-6 1xs:p-10 sm:p-16
                lg:px-16 
                lg:py-32
                bg-white
                rounded-lg
                "
                style={{maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto'}}
            >
              <img 
                  src={EventLastBg}
                  alt=""
                  className="
                  w-[100%] h-[auto]
                  top-8
                  left-16
                  -z-50
                  absolute hidden xl:block"
              />
              <h2 className="text-xl sm:text-4xl text-center max-w-[100%] sm:max-w-[100%] mx-auto font-medium mb-8" style={{display: 'block', width: '100%'}}>
              {allEighth.heading}
            </h2>
            <h2 className="text-xl sm:text-4xl text-center max-w-[100%] sm:max-w-[100%] mx-auto font-medium mb-8" style={{display: 'block', width: '100%'}}>
              {allEighth.subheading}
            </h2>
            <div className="flex flex-col justify-center items-center"><a className="book-now-btn" href="https://form.jotform.com/243122156454349" target="_blank" style={{backgroundColor: '#4054D6', color: '#F1F3F8', display: 'inline-block', padding: '10px 40px', borderRadius: '50px', fontSize:'22px', textTransform: 'uppercase'}}>{allEighth.button.text}</a></div>
        
            </div>
          </div>
        </Wrapper>
    </Layout>
  )
}

export default IndexPage
