import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Components
import EmailForm from "../forms/emailForm"

const PodcastFormSection = ({ heading, heading2, buttonText }) => {
  return (
      <>
      <div className="flex flex-col">
        <div className="flex flex-row mb-8">
          
          
        </div>
        
      </div>
      
      <div id="join" className="flex mt-16 mb-32 flex-row items-center justify-center max-h-[559px]">
        <div className="
        hidden 2xl:flex 
        flex-[0_1_295px]
        ">
          <StaticImage className="h-[464.47px]" src="../../images/podcast-form-image-left.png" alt=""/>
        </div>
        <div className=" md:flex-[0_0_675px] items-center justify-center rounded-lg bg-white" style={{ padding: '100px'}}>
        {heading && 
            <h2 className="text-xl sm:text-4xl text-center max-w-[100%] sm:max-w-[100%] mx-auto font-medium mb-8" style={{display: 'block', width: '100%'}}>
              {heading}
            </h2>
          }
          {heading2 && 
            <h2 className="text-xl sm:text-4xl text-center max-w-[100%] sm:max-w-[100%] mx-auto font-medium mb-8" style={{display: 'block', width: '100%'}}>
              {heading2}
            </h2>
          }
              <div className="flex flex-col justify-center items-center"><a  href="#" style={{backgroundColor: '#4054D6', color: '#F1F3F8', display: 'inline-block', padding: '10px 40px', borderRadius: '50px', fontSize:'22px', textTransform: 'uppercase'}}>{buttonText}</a></div>
          
        </div>
        <div className="hidden 2xl:flex flex-[0_1_341px]">
          <StaticImage className="h-[464.47px]" src="../../images/podcast-form-image-right.png" alt=""/>
        </div>
      </div>
    </>
  )
}

export default PodcastFormSection
