import * as React from "react"

const AboutBox = ({ tagline, position, size, styleVariant }) => {
    if (!tagline) {
        return null
    }
    return position === 'left' ? (
        <div className="
        flex flex-row home-about-left
        lg:max-w-[90%] lg:mr-auto
        justify-between
        w-full my-16
        h-[149px] 1xs:h-[102px] sm:h-[185.98px] lg:h-[250.98px]
        bg-dark
        rounded-tr-full
        rounded-br-full" style={{ backgroundColor: '#FC565A' }}
    > 
            <h3 className="
                flex flex-col 
                justify-center items-center 
                w-full
                font-medium
                text-white 
                text-center
                text-base sm:text-xl lg:text-3xl
                px-4 lg:px-0
                max-w-[800px]
                mx-auto" style={{ color: '#050239', fontSize: '40px', fontWeight: '900', textAlign: 'left', lineHeight: '44px'}}
            >
                {tagline}
            </h3>
    </div>
    ) : position === 'center' ?
    ( 
        styleVariant === 'simple' ? 
        (
        <div className={`
            flex flex-col home-about-center
            justify-center
            w-full my-12 lg:my-24
            px-6 sm:px-0
            h-[210px] 1xs:h-[150px] sm:h-[185.98px] lg:h-[300.98px]
            bg-blue-darkest 
            rounded-lg lg:rounded-full`}
            > 
                <div className="lg:max-w-[80%] mx-auto">
                    <h3 className={`
                        flex flex-col 
                        justify-center items-center 
                        w-full
                        font-medium
                        text-white 
                        text-left
                        text-base sm:text-xl 
                        ${size === 'normal' ? 'lg:text-2xl' 
                        : size === 'large' ? 'lg:text-[45px]'
                        : size === 'medium' ? 'lg:text-[30px]'
                        : 'text-3xl'}
                        `}
                    >
                        {tagline}
                    </h3>
                    <p className="text-base sm:text-xl lg:text-[30px] text-white font-medium hover:text-white">
                    Email: <a href="mailto:info@murabiyoon.com" 
                    className="hover:underline text-white lg:text-[30px] font-medium">
                            info@murabiyoon.com
                    </a></p>
                </div>
        </div> 
        ) : (
            <div className="
            flex flex-row home-about-center
            justify-between
            w-full my-12 lg:my-24
            h-[149px] 1xs:h-[102px] sm:h-[185.98px] lg:h-[250.98px]
            bg-blue-darkest 
            rounded-full
            relative -z-20" style={{ backgroundColor: '#FECB2E'}}
            > 
            <div className="
            bg-dark 
            w-[149px] 1xs:w-[102px] sm:w-[185.98px] lg:w-[250.98px] 
            h-[149px] 1xs:h-[102px] sm:h-[185.98px] lg:h-[250.98px] 
            rounded-full left-0 absolute -z-10" style={{ backgroundColor: '#4054D6'}}
            ></div>
                <h3 className={`
                    flex flex-col 
                    justify-center items-center 
                    w-full
                    font-medium
                    text-white 
                    text-left
                    text-base sm:text-xl 
                    ${size === 'normal' ? 'lg:text-3xl' 
                    : size === 'large' ? 'lg:text-[45px]'
                    : 'lg:text-3xl'}
                    max-w-[90%]
                    mx-auto`} style={{ textAlign: 'left', color: '#4054D6', fontWeight: '900', maxWidth: '700px'}}
                >
                    {tagline}
                </h3>
        </div> 
        )
    ) : (
        <div className="
        flex flex-row 
        justify-between
        w-full
        h-[82px] sm:h-[185.98px] lg:h-[265.98px]
        bg-blue-darker 
        rounded-full"
    > 
            <h3 className="
                flex flex-col 
                justify-center items-center 
                w-full
                font-medium
                text-white 
                text-center
                text-base sm:text-xl lg:text-2xl
                max-w-[800px]
                mx-auto"
            >
                {tagline}
            </h3>
    
        <div className="
            flex-shrink-0
            w-[82px] sm:w-[185.98px] lg:w-[265.98px] 
            h-[82px] sm:h-[185.98px] lg:h-[265.98px]  
            rounded-full
            bg-yellow"
        />
    </div>
    )
}

export default AboutBox